

































































































/* eslint-disable no-undef */
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { fabric } from 'fabric'
import _ from 'lodash'
import { imageFromUrl } from '@/utils/drawing'
import { Api } from '@/edshed-common/api'
import ListSelector from '@/edshed-common/components/ListSelector.vue'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import store from '@/store'
import { SpellingList } from '../../edshed-common/api/types/lists'
import { LoadedWritingPiece, loadWritingPiece, isWord, LoadedWritingPieceWord } from './writing-piece-helper'

Component.registerHooks([
  'beforeRouteEnter'
])
interface ListWord {
  text: string
  count: number
  visible: boolean
}

@Component({ components: { ListSelector } })
export default class WritingPieceView extends Mixins(ModalMixin) {
  private canvasContainer!: HTMLDivElement
  private canvasElement!: HTMLCanvasElement
  private canvas!: fabric.Canvas
  private image!: HTMLImageElement
  private scaleRatio = 1
  private writingPiece: LoadedWritingPiece | null = null

  private listIdent = ''
  private listType = 0
  private list: SpellingList | null = null

  private listWords: ListWord[] = []
  private selectedWords: number[] = []

  private readonly resizeHandler = _.debounce(() => {
    this.resizeCanvas()
    this.$forceUpdate()
  }, 1000)

  // Redirect to new home for non-superusers
  public beforeRouteEnter (to, from, next) {
    if (store.state.user && store.state.user.superuser) {
      next()
    } else {
      let locale = 'en-gb'
      if (store.state.user && store.state.user.locale && store.state.user.locale) {
        locale = store.state.user.locale.toLowerCase().replace('_', '-')
      }
      window.location.replace(`https://www.edshed.com/${locale}/pupils/${to.params.pupil_id}/writing_pieces/view/${to.params.piece_id}`)
    }
  }

  public mounted () {
    window.addEventListener('resize', this.resizeHandler)
    $('body').on('collapsed.pushMenu', this.resizeHandler)
    $('body').on('expanded.pushMenu', this.resizeHandler)

    this.$nextTick(() => {
      this.canvasContainer = this.$refs.canvasContainer as HTMLDivElement
      this.canvasElement = this.$refs.canvas as HTMLCanvasElement

      // (window as any).$('body').addClass('sidebar-collapse')

      const { piece_id } = this.$route.params

      this.load(parseInt(piece_id, 10))
    })
  }

  public destroy () {
    window.removeEventListener('resize', this.resizeHandler)
    $('body').off('collapsed.pushMenu', this.resizeHandler)
    $('body').off('expanded.pushMenu', this.resizeHandler)
  }

  private async onListSelected (value: { list?: string, listType?: number }) {
    if (typeof value.list !== 'undefined') {
      this.listIdent = value.list
    }

    if (typeof value.listType !== 'undefined') {
      this.listType = value.listType
    }

    if (!this.listIdent) {
      this.list = null
      return
    }

    this.list = await Api.getListDetailed(this.listIdent)

    if (!this.list.words) { throw new Error('List has no words!') }

    this.listWords = _.uniq(this.list.words.map(w => w.text)).map(word => ({
      text: word,
      count: this.writingPiece ? this.writingPiece.words.filter(w => w.written_text === word).length : 0,
      visible: true
    })).sort((a, b) => b.count - a.count)

    this.refreshCanvas()
  }

  private onWordVisibilityChanged (word: ListWord) {
    word.visible = !word.visible

    this.refreshCanvas()
  }

  private onWordHover (hoverWord: ListWord) {
    if (!this.writingPiece) { return }

    for (const word of this.writingPiece.words) {
      const visible = this.listWords.some(w => w.text === word.written_text.toLowerCase() && w.visible)
      const highlight = visible && word.written_text.toLowerCase() === hoverWord.text

      word.__rect!.set('stroke', highlight ? 'black' : 'transparent')
      word.__rect!.set('fill', highlight ? 'rgba(255,127,127,0.5)' : (visible ? 'rgba(127,127,255,0.5)' : 'transparent'))
    }

    this.canvas.requestRenderAll()
  }

  private getWordsToList () {
    if (!this.writingPiece) { return [] }

    return this.writingPiece.words.filter(w => isWord(w.correct_text))
  }

  private isBadSpelling (word: LoadedWritingPieceWord) {
    return word.written_text !== word.correct_text
  }

  private isWordSelected (word: LoadedWritingPieceWord) {
    return this.selectedWords.includes(word.idx)
  }

  private onWordSelectionChanged (word: LoadedWritingPieceWord) {
    if (this.isWordSelected(word)) {
      this.selectedWords = this.selectedWords.filter(w => w !== word.idx)
    } else {
      this.selectedWords.push(word.idx)
    }

    // console.log('this.selectedWords', this.selectedWords)
  }

  private refreshCanvas () {
    if (!this.writingPiece) { return }

    for (const word of this.writingPiece.words) {
      if (word.__rect) {
        if (this.listWords.some(w => w.text === word.written_text.toLowerCase() && w.visible)) {
          word.__rect.set('fill', 'rgba(127,127,255,0.5)')
        } else {
          word.__rect.set('fill', 'transparent')
        }
      }
    }

    this.canvas.requestRenderAll()
  }

  private async load (piece_id: number) {
    this.writingPiece = await loadWritingPiece(piece_id)

    for (const word of this.writingPiece.words) {
      if (this.isBadSpelling(word)) {
        this.selectedWords.push(word.idx)
      }
    }

    await this.initCanvas()
  }

  private async initCanvas () {
    if (!this.writingPiece) { return }

    if (this.canvas) {
      this.canvas.dispose()
    }

    this.image = await imageFromUrl(this.writingPiece.__image)

    this.canvasElement.width = this.image.width
    this.canvasElement.height = this.image.height

    this.canvas = new fabric.Canvas(this.canvasElement)

    this.resizeCanvas()

    const imageShape = new fabric.Image(this.image, { left: 0, top: 0 })

    this.canvas.setBackgroundImage(imageShape, () => undefined)

    for (const word of this.writingPiece.words) {
      word.__rect = new fabric.Rect({
        left: word.rect.x,
        top: word.rect.y,
        width: word.rect.width,
        height: word.rect.height,
        angle: word.rect.angle * 180 / Math.PI,
        originX: 'center',
        originY: 'center',
        // stroke: 'red',
        // strokeUniform: true,
        fill: 'transparent',
        hasControls: false,
        selectable: false,
        lockMovementX: true,
        lockMovementY: true
      })

      this.canvas.add(word.__rect)
    }
  }

  private resizeCanvas () {
    this.scaleRatio = (this.canvasContainer.clientWidth - 2) / this.image.width

    if (this.scaleRatio === 0) { return }

    this.canvas.setDimensions({
      width: this.image.width * this.scaleRatio,
      height: this.image.height * this.scaleRatio
    })

    this.canvas.setZoom(this.scaleRatio)
  }

  private async createList () {
    const { writingPiece } = this
    if (!writingPiece) { return }

    const listTitle = await this.prompt({ title: 'List name', message: 'Please enter name for new list' })

    if (!listTitle) { return }

    const words = this.selectedWords.map(idx => writingPiece.words[idx]).map(word => word.correct_text)
    const savedList = await Api.createList({ title: listTitle, words: words.map(w => ({ text: w })) })
    const viewList = await this.confirm({ title: 'View list', message: 'Would you like to view the list now?' })

    if (viewList) {
      this.$router.push('/list/' + savedList.ident)
    }
  }
}
